<template>
  <div class="forget-page">
    <HeadTitle text="忘记密码"></HeadTitle>
    <div class="g-ct-cn forget-cn">
      <el-steps
        :active="active"
        align-center
        class="forget-steps"
      >
        <el-step title="验证身份"></el-step>
        <el-step title="重置登陆密码"></el-step>
        <el-step title="重置成功"></el-step>
      </el-steps>
      <div class="form-wrap">
        <UserConfirm
          v-if="active === 1"
          @goNext="goNext"
        />
        <PasswordConfirm
          v-if="active === 2"
          @goNext="goNext"
          :preFormData="formData"
        />
        <div v-if="active === 3">
          <div class="u-title">恭喜您，密码修改成功！</div>
          <el-button
            round
            type="primary"
            @click="openLogin"
          >去登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {HeadTitle} from '@/components'
import { PasswordConfirm, UserConfirm } from './components'
export default {
  components: {
    HeadTitle,
    PasswordConfirm,
    UserConfirm
  },
  props: {
  },
  data () {
    return {
      active: 1,
      formData: {},
    }
  },
  computed: {},
  methods: {
    async goNext(data) {
      if (data) {
        this.formData = {...data}
      }
      this.active++
    },
    openLogin() {
      this.$store.dispatch('ShowLoginDialog')
    }
  }
}
</script>
<style lang='scss' scoped>
.forget-cn {
  padding: 60px 0 100px;
  text-align: center;
  .form-wrap {
    width: 380px;
    margin: 60px auto;
  }
  .u-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
</style>